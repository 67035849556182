import { API_URL } from 'app/config';
import {
  createCredentials,
  createHeaders,
  handleNotOkResponse,
  getNetworkErrorOrOriginalError,
} from 'common/utils/requestUtils';

import {
  DeviceSettings,
  DashboardData,
  Activity,
  OperationResult,
  OperationResultTypes,
} from './entitiesTypes';

export async function fetchDashboardDataRequest(
  deviceSettings: DeviceSettings
): Promise<DashboardData> {
  try {
    const res = await fetch(`${API_URL}/dashboard/prepareData`, {
      method: 'POST',
      headers: createHeaders(),
      credentials: createCredentials(),
      body: JSON.stringify(deviceSettings),
    });
    handleNotOkResponse(res);
    const dashboardData = (await res.json()) as DashboardData;
    return dashboardData;
  } catch (error) {
    throw getNetworkErrorOrOriginalError(error);
  }
}

export async function updateDashboardActivityRequest(activity: Activity): Promise<OperationResult> {
  try {
    const res = await fetch(`${API_URL}/dashboard/activity`, {
      method: 'POST',
      headers: createHeaders(),
      credentials: createCredentials(),
      body: JSON.stringify(activity),
    });
    handleNotOkResponse(res);
    const result = (await res.json()) as OperationResult;
    if (result.result === OperationResultTypes.FAILED) {
      throw new Error(result.message || 'updateDashboardActivityRequest operation failed');
    }
    return result;
  } catch (error) {
    throw getNetworkErrorOrOriginalError(error);
  }
}
