import { t } from 'test/utils';
import {
  ServiceInfoEntry,
  ServiceInfoGroup,
  ServiceInfoGroupType,
  ServiceInfoGroupTypes,
  SupportNumberName,
  SupportNumberNames,
} from 'features/entities/entitiesTypes';

type ServiceInfoGroupTypeToTranslation = {
  [key in ServiceInfoGroupType]: string;
};

type SupportNumberNameToTranslation = {
  [key in SupportNumberName]: string;
};

export const getGroupLabels = (): ServiceInfoGroupTypeToTranslation => ({
  [ServiceInfoGroupTypes.BOI]: t('common.serviceInfo.labels.boi'),
  [ServiceInfoGroupTypes.EBK_ASIA]: t('common.serviceInfo.labels.eChannelsServiceAsia'),
  [ServiceInfoGroupTypes.EBK]: t('common.serviceInfo.labels.eBankingSwitzerland'),
  [ServiceInfoGroupTypes.EL2_LU]: t('common.serviceInfo.labels.eBankingEurope'),
  [ServiceInfoGroupTypes.EL2_MC]: t('common.serviceInfo.labels.eBankingMonaco'),
  [ServiceInfoGroupTypes.ESE_LU]: t('common.serviceInfo.labels.eServicesEurope'),
  [ServiceInfoGroupTypes.ESE_MC]: t('common.serviceInfo.labels.eServicesMonaco'),
  [ServiceInfoGroupTypes.GENERAL]: t('common.serviceInfo.labels.general'),
  [ServiceInfoGroupTypes.INO2]: t('common.serviceInfo.labels.eServicesIndia'),
  [ServiceInfoGroupTypes.JMA]: t('common.serviceInfo.labels.marketLink'),
  [ServiceInfoGroupTypes.KOS]: t('common.serviceInfo.labels.eServicesGermany'),
  [ServiceInfoGroupTypes.LSB_APAC_MOBILE]: t('common.serviceInfo.labels.eServicesLsbApac'),
});

export const getNameLabels = (): SupportNumberNameToTranslation => ({
  [SupportNumberNames.CHARGEABLE]: t('common.serviceInfo.labels.chargeable'),
  [SupportNumberNames.TOLL_FREE]: t('common.serviceInfo.labels.tollFree'),
});

const sortedGroupTypes: ServiceInfoGroupType[] = [
  ServiceInfoGroupTypes.EBK,
  ServiceInfoGroupTypes.BOI,
  ServiceInfoGroupTypes.JMA,
  ServiceInfoGroupTypes.ESE_LU,
  ServiceInfoGroupTypes.EL2_LU,
  ServiceInfoGroupTypes.ESE_MC,
  ServiceInfoGroupTypes.EL2_MC,
  ServiceInfoGroupTypes.EBK_ASIA,
  ServiceInfoGroupTypes.INO2,
  ServiceInfoGroupTypes.KOS,
  ServiceInfoGroupTypes.GENERAL,
  ServiceInfoGroupTypes.LSB_APAC_MOBILE,
];

export const sortServiceInfoGroupsFn = (group1: ServiceInfoGroup, group2: ServiceInfoGroup) => {
  if (sortedGroupTypes.indexOf(group1.groupType) < sortedGroupTypes.indexOf(group2.groupType)) {
    return -1;
  }

  if (sortedGroupTypes.indexOf(group1.groupType) > sortedGroupTypes.indexOf(group2.groupType)) {
    return 1;
  }

  return 0;
};

/**
 *
 * @param groups
 * @param serviceInfoEntry
 * Add serviceInfoEntry to its corresponding group. Will create the group if
 * it doesn't exist. Targeted for use as the first argument of
 * an Array.prototype.reduce() call
 */
export const groupServiceInfoEntry = (
  groups: ServiceInfoGroup[],
  serviceInfoEntry: ServiceInfoEntry
) => {
  const group = groups.find((g) => g.groupType === serviceInfoEntry.group);
  if (group) {
    group.serviceInfo.push({
      name: serviceInfoEntry.name,
      number: serviceInfoEntry.number,
    });
  } else {
    groups.push({
      groupType: serviceInfoEntry.group,
      // first openingHours for a specific group wins
      openingHours: serviceInfoEntry.openingHours,
      // first imprintUrl for a specific group wins
      imprintUrl: serviceInfoEntry.imprintUrl,
      // first privacyPolicyUrl for a specific group wins
      privacyPolicyUrl: serviceInfoEntry.privacyPolicyUrl,
      serviceInfo: [
        {
          name: serviceInfoEntry.name,
          number: serviceInfoEntry.number,
        },
      ],
    });
  }

  return groups;
};
