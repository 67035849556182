import { createSlice, Action, PayloadAction } from '@reduxjs/toolkit';
import { EnumLiteralsOf, ErrorPayload } from 'common/types';

export type PostQRCodeResponse = {
  rc: number;
};

export type PostQRCodeRequestService = EnumLiteralsOf<typeof PostQRCodeRequestServices>;

export const PostQRCodeRequestServices = Object.freeze({
  connect: 'connect' as 'connect',
  savePaymentScan: 'savePaymentScan' as 'savePaymentScan',
});

export type PostQRCodeRequest = {
  service: PostQRCodeRequestService;
  scanKey: string;
  appInstallKey: string;
  codeline: string;
};

type PaymentScannerState = {
  scanKey?: string;
  error?: string;
  numScans: number;
};

const initialState: PaymentScannerState = {
  numScans: 0,
};

export const paymentScanner = createSlice({
  name: 'paymentScanner',
  initialState,
  reducers: {
    scanCancelledOrTimedOut: (state, action: Action) => {},

    scanConnectQRStart: (state, action: Action) => {},
    scanConnectQRSuccess: (state, action: PayloadAction<string>) => {
      state.scanKey = action.payload;
    },
    scanConnectQRFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.error = action.payload.error;
    },

    scanPaymentQRStart: (state, action: Action) => {},
    scanPaymentQRSuccess: (state, action: Action) => {
      state.numScans = ++state.numScans;
    },
    scanPaymentQRFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.error = action.payload.error;
    },

    stopScanProcess: (state, action: Action) => {
      state.scanKey = undefined;
      state.numScans = 0;
    },
  },
});

export const {
  scanCancelledOrTimedOut,
  scanConnectQRStart,
  scanConnectQRSuccess,
  scanConnectQRFailure,
  scanPaymentQRStart,
  scanPaymentQRSuccess,
  scanPaymentQRFailure,
  stopScanProcess,
} = paymentScanner.actions;

export const paymentScannerReducer = paymentScanner.reducer;

export { initialState as paymentScannerInitialState };
