import React from 'react';
import { Action } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, alpha, Typography, useMediaQuery } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { jbColors } from 'app/theme/jbColors';
import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';
import {
  selectEServicesActivationService,
  selectMarketLinkService,
  selectEntitiesIsFetching,
} from 'features/entities/entitiesSelectors';
import { Toolbar } from 'features/toolbar/Toolbar';
import { TARGET_ENV, TargetEnvItems, scenario } from 'app/config';
import { selectNeedsToAcceptTAndC } from 'features/firstTime/firstTimeSelectors';
import { displayTermsAndThen } from 'features/firstTime/firstTimeSlice';
import { LoadingButton } from 'common/LoadingButton';

import { ReactComponent as JBBuildingVector } from 'common/assets/jb-building.svg';

import background from 'common/assets/jb-hq-background.jpg';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundAttachment: 'scroll',
      height: '100%',
    },

    overlay: {
      backgroundColor: alpha(theme.palette.common.black, 0.3),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },

    toolbarContainer: {
      width: '100%',
      flex: '0 0 auto',
      color: theme.palette.common.white,
    },

    title: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.3,
      letterSpacing: '0.04em',
      textTransform: 'uppercase',
      textAlign: 'center',
      '@media (min-width: 360px)': {
        fontSize: theme.typography.pxToRem(24),
        lineHeight: 1,
        letterSpacing: 0,
      },
    },

    eServicesButton: {
      width: 215,
      '& .MuiButton-root.MuiButton-outlined': {
        padding: '10px 0',
        minWidth: 216,
      },
      marginTop: theme.spacing(4),
      '@media (min-width: 360px)': {
        marginTop: theme.spacing(6),
      },
    },

    marketLinkButton: {
      width: 215,
      marginTop: theme.spacing(2),
      '& .MuiButton-label': {
        fontSize: theme.typography.pxToRem(13),
        letterSpacing: '0.1em',
      },
    },
  })
);

export const HomeNoEBankingActivated = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLargeWidth = useMediaQuery('(min-width:360px)');

  const dispatch = useDispatch();
  const isFetching = useSelector(selectEntitiesIsFetching);
  const eServicesActivation = useSelector(selectEServicesActivationService);
  const marketLinkService = useSelector(selectMarketLinkService);
  const needsToAcceptTAndC = useSelector(selectNeedsToAcceptTAndC);

  const handleActivateEServices = () => {
    let action: Action;

    // In DevCloud we have a custom MapBridge since
    // we don't have an actual device
    if (TARGET_ENV === TargetEnvItems.devcloud) {
      const newEServicesActivation = {
        ...eServicesActivation!,
        url: `${eServicesActivation!.url}?userId=${scenario}`,
      };
      action = openExternalServiceStart(newEServicesActivation);
    } else {
      action = openExternalServiceStart(eServicesActivation!);
    }
    needsToAcceptTAndC ? dispatch(displayTermsAndThen(action)) : dispatch(action);
  };
  const handleMarketLink = () => {
    dispatch(openExternalServiceStart(marketLinkService!));
  };

  return (
    <div className={classes.container}>
      <div className={classes.overlay}>
        <div className={classes.toolbarContainer}>
          <Toolbar />
        </div>

        <Box
          flex="1"
          px={isLargeWidth ? 4.5 : 4}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box
            sx={{
              bgcolor: 'common.white',
              py: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxHeight: '452px',
              aspectRatio: '3 / 4',
              '@media (min-width: 658px)': {
                aspectRatio: 'unset',
                maxWidth: '586px',
                width: '100%',
                alignSelf: 'center',
                py: 8,
              },
            }}
          >
            <Typography variant="h1" className={classes.title}>
              {t('home.title')}
            </Typography>

            <Box mt={3} color={jbColors.app.greyDark}>
              <JBBuildingVector style={{ width: 88, height: 'auto' }} />
            </Box>

            {eServicesActivation && (
              <LoadingButton
                className={classes.eServicesButton}
                variant="outlined"
                color="primary"
                loading={isFetching}
                onClick={handleActivateEServices}
              >
                {t('home.actions.activateEServices')}
              </LoadingButton>
            )}

            {marketLinkService && (
              <Button
                className={classes.marketLinkButton}
                variant="text"
                color="primary"
                onClick={handleMarketLink}
                disabled={isFetching}
              >
                {t('home.actions.marketLink')}
              </Button>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};
