import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/redux/rootReducer';
import { groupServiceInfoEntry, sortServiceInfoGroupsFn } from 'common/utils/contactsUtils';
import {
  ServiceCode,
  ServiceCodes,
  ServiceInfoGroupTypes,
  SupportNumberNames,
} from './entitiesTypes';

export const selectEntities = (state: RootState) => state.entities;

export const selectEntitiesIsFetching = createSelector(
  selectEntities,
  (entities) => entities.isFetching
);

export const selectDashboardData = createSelector(
  selectEntities,
  (entities) => entities.dashboardData
);

export const selectDeviceSettings = createSelector(
  selectEntities,
  (entities) => entities.deviceSettings
);

export const selectLastVisitedScreen = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.lastVisitedScreen
);

export const selectTermsId = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.molTerms?.termsId
);

export const selectTermsText = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.molTerms?.text
);

export const selectCountries = createSelector(selectDashboardData, (dashboardData) => {
  const countries = dashboardData?.molDomicile?.countries
    ? [...dashboardData.molDomicile.countries]
    : undefined;

  if (countries) {
    countries.sort((a, b) => a.name.localeCompare(b.name));
  }

  return countries;
});

export const selectChangePasswordService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.SS1_SSP)
);

export const selectBiometricService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.IDP_BIO)
);

export const selectBoundContractsService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.IDP_BOUND_CONTRACTS)
);

// Leads/Prospect cannot change password
export const selectIsClient = createSelector(
  selectDashboardData,
  (dashboardData) => !!dashboardData?.services?.find((s) => s.code === ServiceCodes.SS1_SSP)
);

export const selectServiceCodes = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.services?.map((s) => s.code) || []
);

const ebkServiceCodes: ServiceCode[] = [
  ServiceCodes.DIG_SG,
  ServiceCodes.DIG_HK,
  ServiceCodes.DIG_SG_LEGACY,
  ServiceCodes.DIG_HK_LEGACY,
  ServiceCodes.EBK,
  ServiceCodes.EBK_R20,
  ServiceCodes.ESE_LU,
  ServiceCodes.ESE_MC,
  ServiceCodes.KOS,
  ServiceCodes.EL2_LU,
  ServiceCodes.EL2_MC,
  ServiceCodes.INO2,
  ServiceCodes.LSB_APAC_MOBILE,
];

export const selectEbkServices = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.services?.filter((s) => ebkServiceCodes.includes(s.code)) || []
);

export const selectEServicesActivationService = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.services?.find((s) => s.code === ServiceCodes.IDP_REG_ESERV)
);

export const selectEbkChService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.EBK)
);

export const selectEbkChR20Service = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.EBK_R20)
);

export const selectProspectRegistrationService = createSelector(
  selectDashboardData,
  (dashboardData) =>
    dashboardData?.services?.find((s) => s.code === ServiceCodes.IDP_REG_MEM_PROSPECT)
);

export const selectTrialRegistrationService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.IDP_REG_MEM_TRIAL)
);

export const selectMarketLinkService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.JMA)
);

export const selectJBWebsiteService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.JB_WEBSITE)
);

export const selectPaymentScannerService = createSelector(selectDashboardData, (dashboardData) =>
  dashboardData?.services?.find((s) => s.code === ServiceCodes.EBK_PAYMENT)
);

export const selectLastAccessedEBankingService = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.lastAccessedEBankingService
);

// we only show the support menu in the landing page
export const selectShouldDisplaySupportMenu = createSelector(
  selectDashboardData,
  (dashboardData) => !!dashboardData?.molLanding
);

export const selectShouldDisplayContact = createSelector(
  selectDashboardData,
  (dashboardData) => !!dashboardData?.molContacts
);

export const selectShouldDisplayInsights = createSelector(
  selectDashboardData,
  (dashboardData) => !!dashboardData?.molInsights
);

export const selectInsightsNotificationCount = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.molInsightsNotificationCount
);

export const selectShouldDisplayOnboarding = createSelector(
  selectDashboardData,
  (dashboardData) => !!dashboardData?.molOnboarding
);

export const selectOnboardingUserType = createSelector(
  selectDashboardData,
  (dashboardData) => dashboardData?.molOnboarding?.userType
);

export const selectGeneralContactNumber = createSelector(
  selectDashboardData,
  (dashboardData) =>
    dashboardData?.molContacts?.serviceInfo?.find(
      (entry) =>
        entry.group === ServiceInfoGroupTypes.GENERAL &&
        entry.name === SupportNumberNames.CHARGEABLE
    )?.number
);

export const selectServiceInfoGroups = createSelector(
  selectDashboardData,
  (dashboardData) =>
    dashboardData?.molContacts?.serviceInfo
      ?.reduce(groupServiceInfoEntry, [])
      .sort(sortServiceInfoGroupsFn) || []
);
