export const preloadImages = (imageUrls: string[]) => {
  return new Promise<void>((resolve, reject) => {
    let remaining = imageUrls.length;

    const onImageLoaded = (ev: Event) => {
      // cleanup
      (ev.currentTarget as HTMLImageElement).removeEventListener('load', onImageLoaded);
      if (--remaining === 0) {
        resolve();
      }
    };

    imageUrls.forEach((url) => {
      const img: HTMLImageElement = document.createElement('img');
      img.addEventListener('load', onImageLoaded);
      img.src = url;
    });
  });
};
