import { BrowserModule, IBrowserService } from '@map/secure-browser';
import {
  ConfigurationModule,
  DialogModule,
  DevicePropertiesModule,
  TranslationModule,
  IPluginRegistry,
  IPluginProvider,
  IDeviceProperties,
  IDialogService,
  ITranslationProvider,
  IConfigurationProvider,
} from '@map/core';
import { BiometricStorageModule, IBiometricStorage } from '@map/biometric-storage';
import { DeviceAuthenticatorModule, IDeviceAuthenticator } from '@map/device-authenticator';
import { IPushTANAuthenticator, PushTANAuthenticatorModule } from '@map/pushtan-authenticator';
import { MapBase } from '@map/application';
import { QRScannerModule, IQRScannerService } from '@map/qr-scanner';

import { DeviceSettings, DeviceTypes } from 'features/entities/entitiesTypes';
import { Dictionary } from 'common/types';

type ConfigurationProviderUserProfile = {
  domicile: string;
  isUserProfileSet: boolean;
};

type ConfigurationProviderTerms = {
  termsAccepted: boolean;
};

type Publication = {
  id: number;
  code: string;
  name: string;
  headline: string;
  language: string;
  publicationDate: string;
  lastModifiedDate: string;
  publicationType?: string[];
  disclaimerType?: string[];
  company: string[];
  country: string[];
  sector: string[];
  assetClass: string[];
  rating: string[];
  ratingChange: string[];
  isRead: boolean;
};

export type PublicationContent = {
  ids: string[];
  byId: Dictionary<Publication>;
};

export type ConfigurationProviderMemberZone = {
  isMemServiceActivated?: boolean;
  financialNews?: {
    lastSyncDate?: string;
    publications?: PublicationContent;
    searchHistory?: string[];
    readItems?: number[];
  };
};

export const initialMemberZoneConf: ConfigurationProviderMemberZone = {
  isMemServiceActivated: false,
  financialNews: {
    publications: {
      ids: [],
      byId: {},
    },
    searchHistory: [],
    readItems: [],
  },
};

// TODO cleanup calls we don't use
export class MapBridge extends MapBase {
  private biometricStorageService: IBiometricStorage | undefined;
  private browserService: IBrowserService | undefined;
  private configurationProvider: IConfigurationProvider | undefined;
  private deviceAuthenticator: IDeviceAuthenticator | undefined;
  private deviceProperties: IDeviceProperties | undefined;
  private dialogService: IDialogService | undefined;
  private translationProvider: ITranslationProvider | undefined;
  private pushTANAuthenticator: IPushTANAuthenticator | undefined;
  private qrScannerService: IQRScannerService | undefined;

  configureRegistrar(registrar: IPluginRegistry) {
    // Call 'use' to include and set up a module
    BiometricStorageModule.use(registrar);
    BrowserModule.use(registrar);
    ConfigurationModule.use(registrar);
    DeviceAuthenticatorModule.use(registrar);
    DevicePropertiesModule.use(registrar);
    DialogModule.use(registrar);
    TranslationModule.use(registrar);
    PushTANAuthenticatorModule.use(registrar);
    QRScannerModule.use(registrar);
  }

  onReady(provider: IPluginProvider) {
    this.biometricStorageService = BiometricStorageModule.getService(provider);
    this.browserService = BrowserModule.getService(provider);
    this.configurationProvider = ConfigurationModule.getService(provider);
    this.deviceAuthenticator = DeviceAuthenticatorModule.getService(provider);
    this.deviceProperties = DevicePropertiesModule.getService(provider);
    this.dialogService = DialogModule.getService(provider);
    this.translationProvider = TranslationModule.getService(provider);
    this.pushTANAuthenticator = PushTANAuthenticatorModule.getService(provider);
    this.qrScannerService = QRScannerModule.getService(provider);
  }

  openPage(url: string) {
    this.browserService?.openPage(url);
  }

  getDeviceSettings(): DeviceSettings {
    if (!this.deviceProperties) {
      throw new Error(`[MapBridge] 'deviceProperties' has not been initialized`);
    }

    const dP = this.deviceProperties;
    const settings: DeviceSettings = {
      selectedDomicile: this.getDomicile(),
      isTermsAccepted: this.getTermsAccepted(),
      isMemServiceActivated: this.getIsMemServiceActivated(),
      language: this.getActiveLanguage(),
      osType: dP.getPlatform(),
      deviceModel: dP.deviceName().substring(0, 100) as string,
      deviceType: dP.isTablet() ? DeviceTypes.TABLET : DeviceTypes.MOBILE,
      appVersion: dP.appVersion().substring(0, 20) as string,
      userAgent: navigator.userAgent.substring(0, 500),
    };

    // We only send two letter codes to the backend
    // otherwise it will be ignored
    if (settings.selectedDomicile && settings.selectedDomicile.length > 2) {
      delete settings.selectedDomicile;
    }

    return settings;
  }

  isMapDevice() {
    return DevicePropertiesModule.isMAPDevice();
  }

  getSupportedBiometricMethod() {
    if (!this.biometricStorageService) {
      throw new Error(`[MapBridge] 'biometricStorageService' has not been initialized`);
    }

    return this.biometricStorageService.getSupportedBiometricMethod();
  }

  getDeviceId() {
    if (!this.deviceProperties) {
      throw new Error(`[MapBridge] 'deviceProperties' has not been initialized`);
    }
    return this.deviceProperties.deviceId() as string;
  }

  getTermsAccepted() {
    if (!this.configurationProvider) {
      throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
    }
    const terms = this.configurationProvider.get('Terms') as ConfigurationProviderTerms;
    return terms.termsAccepted;
  }

  getDomicile() {
    if (!this.configurationProvider) {
      throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
    }
    const userProfile = this.configurationProvider.get(
      'UserProfile'
    ) as ConfigurationProviderUserProfile;
    return userProfile.domicile;
  }

  ensureMemberZoneConfiguration(): Promise<void> {
    return new Promise((fullfill, reject) => {
      if (!this.configurationProvider) {
        return reject(new Error(`[MapBridge] 'configurationProvider' has not been initialized`));
      }

      // if we have more properties in the future consider using a deep merge library
      const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;
      if (!conf.isMemServiceActivated) {
        conf.isMemServiceActivated = initialMemberZoneConf.isMemServiceActivated;
      }
      if (!conf.financialNews) {
        conf.financialNews = initialMemberZoneConf.financialNews;
      }
      if (!conf.financialNews?.publications) {
        conf.financialNews!.publications = initialMemberZoneConf.financialNews?.publications;
      }
      if (!conf.financialNews?.readItems) {
        conf.financialNews!.readItems = initialMemberZoneConf.financialNews?.readItems;
      }
      if (!conf.financialNews?.searchHistory) {
        conf.financialNews!.searchHistory = initialMemberZoneConf.financialNews?.searchHistory;
      }

      return this.configurationProvider.update('MemberZone', conf).then(() => fullfill());
    });
  }

  getIsMemServiceActivated() {
    if (!this.configurationProvider) {
      throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
    }
    const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;
    return conf.isMemServiceActivated;
  }

  updateIsMemServiceActivated(value: boolean): Promise<void> {
    return new Promise((fullfill, reject) => {
      if (!this.configurationProvider) {
        throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
      }
      const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;

      // we dont' check for existence of deep property because of ensureMemberZoneConfiguration
      // that needs to be called at initialization
      conf.isMemServiceActivated = value;

      return this.configurationProvider.update('MemberZone', conf).then(() => fullfill());
    });
  }

  getInsightsConfiguration() {
    if (!this.configurationProvider) {
      throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
    }
    const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;
    return conf.financialNews;
  }

  updateInsightsSearchHistory(searchHistory: string[]): Promise<void> {
    return new Promise((fullfill, reject) => {
      if (!this.configurationProvider) {
        throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
      }
      const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;

      // we dont' check for existence of deep property because of ensureMemberZoneConfiguration
      // that needs to be called at initialization
      conf.financialNews!.searchHistory = searchHistory;

      return this.configurationProvider.update('MemberZone', conf).then(() => fullfill());
    });
  }

  updateInsightsReadItems(readItems: number[]): Promise<void> {
    return new Promise((fullfill, reject) => {
      if (!this.configurationProvider) {
        throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
      }
      const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;

      // we dont' check for existence of deep property because of ensureMemberZoneConfiguration
      // that needs to be called at initialization
      conf.financialNews!.readItems = readItems;

      return this.configurationProvider.update('MemberZone', conf).then(() => fullfill());
    });
  }

  updateInsightsLastSyncDate(lastSyncDate: string): Promise<void> {
    return new Promise((fullfill, reject) => {
      if (!this.configurationProvider) {
        throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
      }
      const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;

      // we dont' check for existence of deep property because of ensureMemberZoneConfiguration
      // that needs to be called at initialization
      conf.financialNews!.lastSyncDate = lastSyncDate;

      return this.configurationProvider.update('MemberZone', conf).then(() => fullfill());
    });
  }

  updateInsightsPublications(publications: PublicationContent): Promise<void> {
    return new Promise((fullfill, reject) => {
      if (!this.configurationProvider) {
        throw new Error(`[MapBridge] 'configurationProvider' has not been initialized`);
      }
      const conf = this.configurationProvider.get('MemberZone') as ConfigurationProviderMemberZone;

      // we dont' check for existence of deep property because of ensureMemberZoneConfiguration
      // that needs to be called at initialization
      conf.financialNews!.publications = publications;

      return this.configurationProvider.update('MemberZone', conf).then(() => fullfill());
    });
  }

  getActiveLanguage() {
    if (!this.translationProvider) {
      throw new Error(`[MapBridge] 'translationProvider' has not been initialized`);
    }
    return this.translationProvider.getActiveLanguage();
  }

  authenticateDevice(message: string) {
    if (!this.deviceAuthenticator) {
      throw new Error(`[MapBridge] 'deviceAuthenticator' has not been initialized`);
    }
    return this.deviceAuthenticator.authenticate(message);
  }

  hasBinding() {
    if (!this.deviceAuthenticator) {
      throw new Error(`[MapBridge] 'deviceAuthenticator' has not been initialized`);
    }
    return this.deviceAuthenticator.hasBinding();
  }

  openErrorDialog(title: string, message: string) {
    return this.dialogService?.notify(title, message);
  }

  closeSecureBrowser() {
    return this.browserService?.closePage();
  }

  getEmCert(message: string) {
    if (!this.pushTANAuthenticator) {
      throw new Error(`[MapBridge] 'pushTANAuthenticator' has not been initialized`);
    }
    return this.pushTANAuthenticator.getEmCert(message);
  }

  commitEmCertUpdate() {
    if (!this.pushTANAuthenticator) {
      throw new Error(`[MapBridge] 'pushTANAuthenticator' has not been initialized`);
    }
    return this.pushTANAuthenticator.commitEmCertUpdate();
  }

  hasEmCertChanged() {
    if (!this.pushTANAuthenticator) {
      throw new Error(`[MapBridge] 'pushTANAuthenticator' has not been initialized`);
    }
    return this.pushTANAuthenticator.hasEmCertChanged();
  }

  handleScanError({ errorMessage }: { errorMessage: string }) {
    console.log(errorMessage);
  }

  async scanQrCode() {
    if (!this.qrScannerService) {
      throw new Error(`[MapBridge] 'qrScannerService' has not been initialized`);
    }

    const { qrCode } = await this.qrScannerService.scanQRCode();
    return qrCode;
  }
}
