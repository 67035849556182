// This module contains the business logic to integrate
// with the Financial News iframe. Applications inside an iframe
// cannot access the MAP interface so we need to accomodate them
// via postMessage()
import { Action } from '@reduxjs/toolkit';

import {
  MEMBER_ZONE_URL,
  ORIGINS_WHITELIST,
  scenario,
  TargetEnvItems,
  TARGET_ENV,
} from 'app/config';
import { store } from 'app/redux/store';
import { EnumLiteralsOf } from 'common/types';
import { selectProspectRegistrationService } from 'features/entities/entitiesSelectors';
import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';
import { getMapBridge } from 'features/map';
import { PublicationContent } from 'features/map/MapBridge';

export type MessageToDDAction = EnumLiteralsOf<typeof MessageToDDActions>;

export const MessageToDDActions = Object.freeze({
  MAP_AUTHENTICATE_DEVICE: 'MAP_AUTHENTICATE_DEVICE' as 'MAP_AUTHENTICATE_DEVICE',
  MAP_GET_DEVICE_SETTINGS: 'MAP_GET_DEVICE_SETTINGS' as 'MAP_GET_DEVICE_SETTINGS',
  MAP_OPEN_PAGE: 'MAP_OPEN_PAGE' as 'MAP_OPEN_PAGE',
  MAP_SET_FINANCIAL_NEWS_SEARCH_HISTORY: 'MAP_SET_FINANCIAL_NEWS_SEARCH_HISTORY' as 'MAP_SET_FINANCIAL_NEWS_SEARCH_HISTORY',
  MAP_SET_FINANCIAL_NEWS_READ_ITEMS: 'MAP_SET_FINANCIAL_NEWS_READ_ITEMS' as 'MAP_SET_FINANCIAL_NEWS_READ_ITEMS',
  OPEN_PROSPECT_ACTIVATION_PAGE: 'OPEN_PROSPECT_ACTIVATION_PAGE' as 'OPEN_PROSPECT_ACTIVATION_PAGE',
});

export type MessageFromDDAction = EnumLiteralsOf<typeof MessageFromDDActions>;

export const MessageFromDDActions = Object.freeze({
  MAP_AUTHENTICATE_DEVICE_RESULT: 'MAP_AUTHENTICATE_DEVICE_RESULT' as 'MAP_AUTHENTICATE_DEVICE_RESULT',
  MAP_GET_DEVICE_SETTINGS_RESULT: 'MAP_GET_DEVICE_SETTINGS_RESULT' as 'MAP_GET_DEVICE_SETTINGS_RESULT',
});

type DDMZMessage = {
  action: MessageToDDAction | MessageFromDDAction;
  payload?: any;
};

const handleMapAuthenticateDevice = async (initializationToken: string) => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();
    const authenticatorResponse = await mapBridge.authenticateDevice(initializationToken);
    messageMemberZone(MessageFromDDActions.MAP_AUTHENTICATE_DEVICE_RESULT, authenticatorResponse);
  } catch (err) {
    console.error(err);
  }
};

const handleMapDeviceSettings = async () => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();
    const lang = mapBridge.getActiveLanguage().toLowerCase();
    const deviceId = mapBridge.getDeviceId();
    messageMemberZone(MessageFromDDActions.MAP_GET_DEVICE_SETTINGS_RESULT, {
      lang,
      deviceId,
      financialNews: mapBridge.getInsightsConfiguration(),
    });
  } catch (err) {
    console.error(err);
  }
};

const handleMapOpenPage = async (url: string) => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();
    mapBridge.openPage(url);
  } catch (err) {
    console.error(err);
  }
};

const handleSetInsightsSearchHistory = async (searchHistory: string[]) => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();
    await mapBridge.updateInsightsSearchHistory(searchHistory);
  } catch (err) {
    console.error(err);
  }
};

const handleSetInsightsReadItems = async (readItems: number[]) => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();
    await mapBridge.updateInsightsReadItems(readItems);
  } catch (err) {
    console.error(err);
  }
};

const handleSetInsightsLastSyncDate = async (lastSyncDate: string) => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();
    await mapBridge.updateInsightsLastSyncDate(lastSyncDate);
  } catch (err) {
    console.error(err);
  }
};

const handleSetInsightsPublications = async (publications: PublicationContent) => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();
    await mapBridge.updateInsightsPublications(publications);
  } catch (err) {
    console.error(err);
  }
};

const openProspectActivationPage = async () => {
  try {
    const mapBridge = getMapBridge();
    await mapBridge.ready();

    let action: Action;
    const state = store.getState();
    const prospectService = selectProspectRegistrationService(state);
    if (TARGET_ENV === TargetEnvItems.devcloud) {
      const newProspectService = {
        ...prospectService!,
        url: `${prospectService!.url}?userId=${scenario}`,
      };
      action = openExternalServiceStart(newProspectService);
    } else {
      action = openExternalServiceStart(prospectService!);
    }
    store.dispatch(action);
  } catch (err) {
    console.error(err);
  }
};

// handles incoming messages from Dynamic Dashboard
const handleMessage = (ev: MessageEvent) => {
  if (ORIGINS_WHITELIST.includes(ev.origin)) {
    const { action, payload } = ev.data;
    if (action) {
      switch (action) {
        case 'MAP_AUTHENTICATE_DEVICE':
          handleMapAuthenticateDevice(payload);
          break;

        case 'MAP_GET_DEVICE_SETTINGS':
          handleMapDeviceSettings();
          break;

        case 'MAP_OPEN_PAGE':
          handleMapOpenPage(payload);
          break;

        case 'MAP_SET_FINANCIAL_NEWS_SEARCH_HISTORY':
          handleSetInsightsSearchHistory(payload);
          break;

        case 'MAP_SET_FINANCIAL_NEWS_READ_ITEMS':
          handleSetInsightsReadItems(payload);
          break;

        case 'MAP_SET_FINANCIAL_NEWS_LAST_SYNC_DATE':
          handleSetInsightsLastSyncDate(payload);
          break;

        case 'MAP_SET_FINANCIAL_NEWS_PUBLICATIONS':
          handleSetInsightsPublications(payload);
          break;

        case 'OPEN_PROSPECT_ACTIVATION_PAGE':
          openProspectActivationPage();
          break;
        default:
      }
    }
  }
};

// sends messages to Dynamic Dashboard
export const messageMemberZone = (action: MessageFromDDAction, payload?: any) => {
  try {
    const message: DDMZMessage = { action };
    if (payload) {
      message.payload = payload;
    }
    if (window.frames.insights && MEMBER_ZONE_URL) {
      window.frames.insights.postMessage(message, MEMBER_ZONE_URL);
    }
  } catch (e) {
    console.error('[Dynamic Dashboard] Error sending message to `financialNews` iframe');
    console.error(e);
  }
};

export const initializeInsightsMessaging = () => {
  window.addEventListener('message', handleMessage, false);
};

export const tearDownInsightsMessaging = () => {
  window.removeEventListener('message', handleMessage);
};
