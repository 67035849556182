import { createSlice, Action, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticatorResponse } from '@map/device-authenticator';

import { EnumLiteralsOf, ErrorPayload } from 'common/types';
import { ActualSupportedBiometricMethod, DeviceSettings } from 'features/entities/entitiesTypes';

export type SetDeviceSettingsPayload = Partial<DeviceSettings>;

export type ResponseResult = EnumLiteralsOf<typeof ResponseResults>;

export const ResponseResults = Object.freeze({
  // we need to explicitly cast values to get the proper literal type
  SUCCESS: 'SUCCESS' as 'SUCCESS',
  FAILED: 'FAILED' as 'FAILED',
});

export type AuthenticateResponse = {
  message: string;
  result: ResponseResult;
};

export type MainViewElement = EnumLiteralsOf<typeof MainViewElements>;

export const MainViewElements = Object.freeze({
  // we need to explicitly cast values to get the proper literal type
  MainApp: 'MainApp' as 'MainApp',
  FirstTimeOrchestrator: 'FirstTimeOrchestrator' as 'FirstTimeOrchestrator',
});

export type MainView = {
  screenType: MainViewElement;
  screenProps: any;
};

export type MainViewMap = {
  [key in MainViewElement]: MainView;
};

export type CertUpdateResponse = AuthenticateResponse;

export type DeviceSettingsPayload = {
  deviceSettings: DeviceSettings;
  supportedBiometricMethod: ActualSupportedBiometricMethod;
};

export type SessionState = {
  view?: MainView;
  shouldDisplayUpdateScreen?: boolean;
  deviceSettings?: DeviceSettings;
  supportedBiometricMethod?: ActualSupportedBiometricMethod;
  certificateRenewalToken?: string;
  initializationToken?: string;
  mapCertificateRenewalJwt?: string;
  mapJwt?: string;
  authenticationResult?: ResponseResult;
  certificateRenewalResult?: ResponseResult;
  isFetching: boolean;
  isLastVisitedScreenRestored: boolean;
  error?: string;
};

const views: MainViewMap = {
  [MainViewElements.FirstTimeOrchestrator]: {
    screenType: MainViewElements.FirstTimeOrchestrator,
    screenProps: {},
  },
  [MainViewElements.MainApp]: {
    screenType: MainViewElements.MainApp,
    screenProps: {},
  },
};

const initialState: SessionState = {
  isFetching: false,
  isLastVisitedScreenRestored: false,
};

export const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    authenticateStart: (state, action: PayloadAction<string>) => {
      state.isFetching = true;
    },
    authenticateSuccess: (state, action: PayloadAction<AuthenticateResponse>) => {
      state.isFetching = false;
      state.authenticationResult = action.payload.result;
    },
    authenticateFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    certUpdateStart: (state, action: PayloadAction<string>) => {
      state.isFetching = true;
    },
    certUpdateSuccess: (state, action: PayloadAction<CertUpdateResponse>) => {
      state.isFetching = false;
      state.certificateRenewalResult = action.payload.result;
    },
    certUpdateFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    displayUpdateScreen: (state, action: Action) => {
      state.shouldDisplayUpdateScreen = true;
    },

    fetchDeviceSettingsStart: (state, action: Action) => {
      state.isFetching = true;
    },
    fetchDeviceSettingsSuccess: (state, action: PayloadAction<DeviceSettingsPayload>) => {
      state.isFetching = false;
      state.deviceSettings = action.payload.deviceSettings;
      state.supportedBiometricMethod = action.payload.supportedBiometricMethod;
    },
    fetchDeviceSettingsFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    initAuthenticationStart: (state, action: Action) => {
      state.isFetching = true;
    },
    initAuthenticationSuccess: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
      state.initializationToken = action.payload;
    },
    initAuthenticationFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    initCertUpdateStart: (state, action: Action) => {
      state.isFetching = true;
    },
    initCertUpdateSuccess: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
      state.certificateRenewalToken = action.payload;
    },
    initCertUpdateFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    mapCommitEmCertSuccess: (state, action: Action) => {},
    mapCommitEmCertFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.error = action.payload.error;
    },

    mapDeviceAuthenticationSuccess: (state, action: PayloadAction<AuthenticatorResponse>) => {
      state.mapJwt = action.payload.jwt;
    },
    mapDeviceAuthenticationFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.error = action.payload.error;
    },

    mapGetEmCertSuccess: (state, action: PayloadAction<AuthenticatorResponse>) => {
      state.mapCertificateRenewalJwt = action.payload.jwt;
    },
    mapGetEmCertFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.error = action.payload.error;
    },
    setMainView: (state, action: PayloadAction<MainViewElement>) => {
      state.view = views[action.payload];
    },
    setIsLastVisitedScreenRestored: (state, action: PayloadAction<boolean>) => {
      state.isLastVisitedScreenRestored = action.payload;
    },
  },
});

export const {
  authenticateStart,
  authenticateSuccess,
  authenticateFailure,
  displayUpdateScreen,
  certUpdateStart,
  certUpdateSuccess,
  certUpdateFailure,
  fetchDeviceSettingsStart,
  fetchDeviceSettingsSuccess,
  fetchDeviceSettingsFailure,
  initAuthenticationStart,
  initAuthenticationSuccess,
  initAuthenticationFailure,
  initCertUpdateStart,
  initCertUpdateSuccess,
  initCertUpdateFailure,
  mapCommitEmCertSuccess,
  mapCommitEmCertFailure,
  mapDeviceAuthenticationSuccess,
  mapDeviceAuthenticationFailure,
  mapGetEmCertSuccess,
  mapGetEmCertFailure,
  setMainView,
  setIsLastVisitedScreenRestored,
} = session.actions;

export const sessionReducer = session.reducer;

export { initialState as sessionInitialState };
